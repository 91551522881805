// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List sessions by user ID and agent ID. GET /api/sessions */
export async function listSessionsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listSessionsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseListSession_>('/api/sessions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Init session and returns session ID. POST /api/sessions */
export async function initSessionUsingPOST(body: API.Session, options?: { [key: string]: any }) {
  return request<API.ItemResponseString_>('/api/sessions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Query Agent Session For Client Side. GET /api/sessions/2c/agent */
export async function query2CAgentSessionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.query2CAgentSessionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/sessions/2c/agent', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Create Agent Session For Client Side. POST /api/sessions/2c/agent */
export async function create2CAgentSessionUsingPOST(
  body: API.AgentSessionRequestDTO,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/sessions/2c/agent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Query Storybook Session For Client Side. GET /api/sessions/2c/storybook */
export async function query2CStorybookSessionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.query2CStorybookSessionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/sessions/2c/storybook', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Create Storybook Session For Client Side. POST /api/sessions/2c/storybook */
export async function create2CStorybookSessionUsingPOST(
  body: API.StorybookSessionRequestDTO,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/sessions/2c/storybook', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
