import 'react-toastify/dist/ReactToastify.css';
import '@/styles/global.css';

import {
  AuthProvider,
  EAuthEnv,
  initAuthConfig
} from '@white-matrix/matrix-universe-auth-sdk';
import { domAnimation, LazyMotion } from 'framer-motion';
import { BASE_URL, CHAIN_ENV } from 'gatsby-env-variables';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}
const queryClient = new QueryClient();

initAuthConfig({
  env: CHAIN_ENV as EAuthEnv,
  toast: (message, type) => {
    toast[type](message);
  },
  getAccessToken: async (authCode: string) => {
    if (!authCode) return;
    const data = await fetch(
      `${BASE_URL}/api/auth/callback?token=${encodeURIComponent(authCode)}`,
      {
        method: 'GET'
      }
    );
    if (data.status === 200) {
      return await data.text();
    } else {
      throw new Error('Failed to get access token');
    }
  }
});
export function RootElement(props: RootElementProps) {
  const { children } = props;

  return (
    <I18nextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          autoClose={5000}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={false}
        />
        <AuthProvider>
          <LazyMotion features={domAnimation}>{children}</LazyMotion>
        </AuthProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
