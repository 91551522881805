export const AgentsList = [
  {
    name: 'Asaka Yoshihin',
    age: 25,
    zodiacSign: 'Sagittarius',
    shortDesc: `Asaka is all energy, all the time. Her infectious positivity affects everyone who meets her. Just don't let her sing. `,
    src: '/avatarKimono.png',
    key: 'kimono'
  },
  {
    name: 'Aimme',
    age: 26,
    zodiacSign: 'Leo',
    shortDesc: `Wealthy, talented, cultured. All describe the shining star that is Aimee and even if she doesn't mean to, she will let you know through every action she takes. `,
    src: '/avaterAlice.png',
    key: 'alice'
  },
  {
    name: 'Ragnar',
    age: 34,
    zodiacSign: 'Virgo',
    shortDesc: `Ragnar, the greatest warrior the land has ever known. His strength knows no bounds and is not afraid to prove it.
    `,
    src: '/avaterMaid.png',
    key: 'maid'
  }
];

export const getRoleData = (role) => {
  if (role === 'maid') {
    return {
      name: 'RAGNAR ',
      age: 34,
      zodiacSign: 'Virgo',
      shortDesc: `Ragnar, the greatest warrior the land has ever known. His strength knows no bounds and is not afraid to prove it.
      `,
      src: '/AI_btn_maid_Selected.png',
      key: 'maid'
    };
  }
  if (role === 'kimono') {
    return {
      name: 'Asaka Yoshihin',
      age: 25,
      zodiacSign: 'Sagittarius',
      shortDesc: `Asaka is all energy, all the time. Her infectious positivity affects everyone who meets her. Just don't let her sing. `,
      src: '/AI_btn_kimono_Selected.png',
      key: 'kimono'
    };
  }
  if (role === 'alice') {
    return {
      name: 'Aimme',
      age: 26,
      zodiacSign: 'Leo',
      shortDesc: `Wealthy, talented, cultured. All describe the shining star that is Aimee and even if she doesn't mean to, she will let you know through every action she takes. `,
      src: '/AI_btn_Alice_Selected.png',
      key: 'alice'
    };
  }
};

export const getStoryDes = (id) => {
  if (id === '10db719268ab4a11a378b44c64ce4b4c') {
    return {
      name: 'Dungeons & Dragons',
      desc: `Agent and the player are engaged in a game of Dungeons & Dragons. The player takes on the role of a level 6 Gnome ranger, tasked with hunting down the greedy dragon Fortisnax. However, Fortisnax has laid traps and assembled groups of bandits to thwart the player's progress.`,
      star: 10,
      bg: `md:bg-[url(/storybooks/list_bg_1.png)] bg-[url(/storybooks/list_bg_m_1.png)]`,
      id: '10db719268ab4a11a378b44c64ce4b4c'
    };
  }
  if (id === 'b745cd4e86ef4465907b2c2c645df9bb') {
    return {
      name: 'A Stranger To A New World',
      desc: `The player has been teleported to a strange new world filled with magic and fantasy they have only ever read in books. The agent and player must work together to find a way for the player to return to their original home. `,
      star: 10,
      bg: `md:bg-[url(/storybooks/list_bg_2.png)] bg-[url(/storybooks/list_bg_m_2.png)]`,
      id: 'b745cd4e86ef4465907b2c2c645df9bb'
    };
  }
  if (id === '8bf7e0b2b6c14552bd81d6ad18990b7a') {
    return {
      name: 'The Greatest Warrior',
      desc: `The player is travelling across the country searching for adventure when the warrior appears and demands that the two fight as the warrior is travelling the land facing only the strongest and most fierce fighters to regain his honor as the greatest warrior who ever lived.`,
      star: 10,
      bg: `md:bg-[url(/storybooks/list_bg_3.png)] bg-[url(/storybooks/list_bg_m_3.png)]`,
      id: '8bf7e0b2b6c14552bd81d6ad18990b7a'
    };
  }
};
