exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agents-chat-[id]-tsx": () => import("./../../../src/pages/agents/chat/[id].tsx" /* webpackChunkName: "component---src-pages-agents-chat-[id]-tsx" */),
  "component---src-pages-agents-index-tsx": () => import("./../../../src/pages/agents/index.tsx" /* webpackChunkName: "component---src-pages-agents-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journeys-index-tsx": () => import("./../../../src/pages/journeys/index.tsx" /* webpackChunkName: "component---src-pages-journeys-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-storybooks-index-tsx": () => import("./../../../src/pages/storybooks/index.tsx" /* webpackChunkName: "component---src-pages-storybooks-index-tsx" */)
}

