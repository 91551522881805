import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';

import { useAppStore } from '@/store/appStore';
import { getQuery } from '@/utils/common';
enum Role {
  Kimono = 'kimono',
  Alice = 'alice',
  Maid = 'maid'
}
enum Story {
  One = '10db719268ab4a11a378b44c64ce4b4c',
  TWO = 'b745cd4e86ef4465907b2c2c645df9bb',
  THREE = '8bf7e0b2b6c14552bd81d6ad18990b7a'
}
export function Content(props) {
  const { children } = props;
  const { pathname } = useLocation();
  const { role, story, isStory } = useAppStore();

  const bg = useMemo(() => {
    let _bg = '';
    switch (role) {
      case Role.Maid:
        _bg = `bg-[url('@/assets/images/chat/AI_sp_m_bg1.png')]
        md:bg-[url('@/assets/images/chat/AI_sp_bg1.png')]`;
        break;
      case Role.Kimono:
        _bg = `bg-[url('@/assets/images/chat/AI_sp_m_bg2.png')]
          md:bg-[url('@/assets/images/chat/AI_sp_bg2.png')]`;
        break;
      case Role.Alice:
        _bg = `bg-[url('@/assets/images/chat/AI_sp_m_bg3.png')]
          md:bg-[url('@/assets/images/chat/AI_sp_bg3.png')]`;
        break;
      default:
        _bg = `bg-[url('@/assets/images/chat/AI_sp_m_bg1.png')]
        md:bg-[url('@/assets/images/chat/AI_sp_bg1.png')]`;
        break;
    }
    return _bg;
  }, [role]);
  const storybg = useMemo(() => {
    let _bg = '';
    switch (story) {
      case Story.One:
        _bg = `bg-[url('/storybooks/bg_m_1.png')]
        md:bg-[url('/storybooks/bg_1.png')]`;
        break;
      case Story.TWO:
        _bg = `bg-[url('/storybooks/bg_m_2.png')]
        md:bg-[url('/storybooks/bg_2.png')]`;
        break;
      case Story.THREE:
        _bg = `bg-[url('/storybooks/bg_m_3.png')]
        md:bg-[url('/storybooks/bg_3.png')]`;
        break;
      default:
        _bg = `bg-[url('/storybooks/bg_m_1.png')]
        md:bg-[url('/storybooks/bg_1.png')]`;
        break;
    }
    return _bg;
  }, [story]);

  // min-h-[calc(100dvh_-_71px)] lg:min-h-[calc(100dvh_-_80px)] 3xl:min-h-[calc(100dvh_-_84px)]
  if (pathname?.includes('profile')) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      <div className="relative min-h-[calc(100dvh_-_71px)] w-[100%] overflow-hidden bg-cover bg-center lg:min-h-[calc(100dvh_-_80px)]   xl:min-w-[1080px] 3xl:min-h-[calc(100dvh_-_84px)]">
        {children}
      </div>
    );
  } else if (
    pathname?.includes('journeys') ||
    pathname?.includes('storybooks')
  ) {
    // journeys storybooks 一样的背景
    return (
      <div
        className={cn(
          storybg,
          'relative min-h-[calc(100dvh_-_71px)] w-[100%] overflow-hidden bg-cover bg-center lg:min-h-[calc(100dvh_-_80px)]   xl:min-w-[1080px] 3xl:min-h-[calc(100dvh_-_84px)]'
        )}
      >
        {children}
      </div>
    );
  } else {
    // 这里还要进行区分是story还是普通的chat
    if (isStory) {
      return (
        <div
          className={cn(
            storybg,
            'relative min-h-[calc(100dvh_-_71px)] w-[100%] overflow-hidden bg-cover bg-center lg:min-h-[calc(100dvh_-_80px)] xl:min-w-[1080px]  3xl:min-h-[calc(100dvh_-_84px)]'
          )}
        >
          {children}
        </div>
      );
    } else {
      return (
        <div
          className={cn(
            bg,
            'relative min-h-[calc(100dvh_-_71px)] w-[100%] overflow-hidden bg-cover bg-center lg:min-h-[calc(100dvh_-_80px)] xl:min-w-[1080px]  3xl:min-h-[calc(100dvh_-_84px)]'
          )}
        >
          {children}
        </div>
      );
    }
  }
}
