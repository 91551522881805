import Live2dWidget from '@white-matrix/live2d-lib';
import { useAuthStore } from '@white-matrix/matrix-universe-auth-sdk';
import cn from 'classnames';
import { m } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';

import { PersonInfo } from '@/components/common/PersonInfo';
import api from '@/services/api';
import { useAppStore } from '@/store/appStore';
import { getAgentId, getQuery, getSessionId } from '@/utils/common';
import { getRoleData } from '@/utils/staticRoleData';

import { Role } from './constant';

export function ChatContent() {
  const { role, setRole, changeWord, setIsStory } = useAppStore();
  const [isShowCanvas, setIsShowCanvas] = useState('left-[100%]');
  const [personality, setPersonality] = useState<any>();
  const { userInfo } = useAuthStore();

  useEffect(() => {
    setIsStory(getQuery(location?.search)?.isStory === 'true');
  }, []);
  useEffect(() => {
    if (!userInfo?.userId) {
      setPersonality(undefined);
    }
    setPersonality(getRoleData(role));
  }, [role, userInfo?.userId]);

  // 获取动态的firstDesc
  useEffect(() => {
    const agentId = getAgentId(role);
    api.Agent.getAgentProfileUsingGET({ agentId })
      .then((res) => {
        if (res?.status === 'OK') {
          changeWord(
            res?.data?.profile?.personality?.firstMetGreetings?.words || '',
            res?.data?.profile?.personality?.greetings?.words || ''
          );
        }
      })
      .catch(() => {
        toast.error('Failed to get data');
      });
  }, [role]);

  // 适配不同屏幕canvas的宽高
  const getLive2D = () => {
    const w = window.innerWidth || document.documentElement.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight;
    const curW = Math.ceil(w < 720 ? w * 0.7 : w * 0.4);
    const curH = Math.ceil(w < 720 ? h * 0.98 : h * 0.98);
    if (role === Role.Kimono) {
      void Live2dWidget.init({
        canvas: {
          width: curW,
          height: curH
        },
        scale: 1,
        debug: false,
        target: document.getElementById('sample') as HTMLDivElement,
        source: {
          path: '/live2d/models',
          models: ['haru']
        }
      });
      setIsShowCanvas(' left-[0vw] md:left-0 bottom-[-2vh]  md:bottom-[-2vh]');
    } else {
      setIsShowCanvas(' left-[100%]');
    }
  };
  useEffect(() => {
    getLive2D();
  }, [role]);
  useMount(() => {
    getLive2D();
  });
  // Live2dWidget.on(type: HitvArea, callback: ()=>void)

  // 右侧图片left偏移值
  const imgLeft = useCallback(
    (curRole) => {
      if (role === curRole) {
        if (curRole === Role.Kimono) {
          return 'md:left-[11.0%] left-[17.9%] h-[11.37vh] md:h-[22.46vh]';
        }
        if (curRole === Role.Alice) {
          return 'md:left-[5%] left-[9%] h-[12.37vh] md:h-[22.46vh]';
        }
        if (curRole === Role.Maid) {
          return 'md:left-[1.57%] left-[2.57%] h-[15.37vh] md:h-[30.5vh]';
        }
      } else {
        if (curRole === Role.Kimono) {
          return ' md:left-[7.3%] bottom-[3px] left-[10.9%] h-[11.37vh] md:h-[22.46vh]';
        }
        if (curRole === Role.Alice) {
          return 'md:left-[1.8%] left-[2%] h-[12.37vh] md:h-[22.46vh]';
        }
        if (curRole === Role.Maid) {
          return 'md:left-[0.6%] left-[1%] bottom-[1px] h-[13.37vh] md:h-[25.5vh]';
        }
      }
    },
    [role]
  );

  const roleBtImg: Array<{
    path: string;
    selectedPath: string;
    bgSelectedCss: string;
    key: string;
  }> = [
    {
      path: '/AI_btn_kimono.png',
      selectedPath: '/AI_btn_kimono_Selected.png',
      bgSelectedCss:
        "bg-[url('/AI_btn_kimono_bg_Selected_m.png')] md:bg-[url('/AI_btn_kimono_bg_Selected.png')]",
      key: 'kimono'
    },

    {
      path: '/AI_btn_Alice.png',
      key: 'alice',
      bgSelectedCss:
        "bg-[url('/AI_btn_Alice_bg_Selected_m.png')] md:bg-[url('/AI_btn_Alice_bg_Selected.png')]",
      selectedPath: '/AI_btn_Alice_Selected.png'
    },
    {
      path: '/AI_btn_maid.png',
      key: 'maid',
      bgSelectedCss:
        "bg-[url('/AI_btn_maid_bg_Selected_m.png')] md:bg-[url('/AI_btn_maid_bg_Selected.png')]",
      selectedPath: '/AI_btn_maid_Selected.png'
    }
  ];

  const onChat = async () => {
    const id = await getSessionId(role);
    if (!id) {
      toast.warning('Failed to get session id');
      return;
    }
    void navigate(`/agents/chat/${id}`);
  };
  return (
    <div className="relative flex w-[100vw]">
      <div className="flex-[538] overflow-y-hidden md:flex-[885]">
        {role === Role.Maid && (
          <m.div className="z-10 h-[calc(100vh_-_7.26vh)] w-full cursor-pointer touch-none overflow-x-hidden md:h-[calc(100vh_-_7.77vh)]">
            <img
              src="/AI_maid.png"
              className={cn(
                'absolute left-[-30vw] bottom-2 top-auto z-[0] h-[90vh] w-auto md:bottom-auto md:top-2 md:left-0 md:h-auto'
              )}
            />
          </m.div>
        )}
        {role === Role.Alice && (
          <m.div className=" h-[100%] w-[100%] overflow-y-hidden">
            <img
              src="/AI_Alice.png"
              className={cn(
                'absolute left-[2.5vw] bottom-2 top-auto h-[90vh]  bg-cover bg-[center_top] bg-no-repeat md:bottom-auto md:top-2 md:left-0 md:h-auto md:w-[45vw]'
              )}
            />
          </m.div>
        )}

        <div
          id="sample"
          className={cn(
            isShowCanvas,
            "fixed bg-[url('/AI_kimono_art.png')] bg-[length:100%_50%] bg-[center_top] bg-no-repeat md:bg-[length:100%_70%] md:bg-center"
          )}
        ></div>
      </div>
      <div className="z-10 flex min-h-[calc(100dvh_-_71px)] flex-[197]  flex-col text-[#FFFFFF] md:flex-[1035] md:flex-row lg:min-h-[calc(100dvh_-_80px)] 3xl:min-h-[calc(100dvh_-_84px)]">
        <div className="flex-[760] bg-[url('/bgt_m.png')] p-1 md:relative  md:flex-[416] md:bg-[url('/bgt.png')] md:pl-[11px] md:pt-[5.5vh] md:backdrop-blur-sm">
          <PersonInfo personality={personality} />

          <div className="fixed left-0 bottom-6 w-[20.40vw] rounded-r-[9.44vh] border-[3px] border-l-[0px]  border-solid bg-gradient-to-r from-[#417876]/80 via-[#08f1e9]/80 to-[#3192fd]/80 py-[1vh]  px-[2vh]   md:absolute md:bottom-[2vh] md:left-[50%] md:my-4 md:w-[60%] md:translate-x-[-50%] md:rounded-xl md:border-0 md:from-[#00FFF6] md:via-[#00FFF6] md:to-[#00FFF6] md:px-5 ">
            <div
              onClick={onChat}
              className="flex cursor-pointer justify-center text-center font-franklin text-base font-[600] md:text-center  lg:text-3xl xl:text-5xl"
            >
              CHAT
            </div>
          </div>
        </div>
        <div className="shrink-1 fixed bottom-6 right-0 flex flex-[574] flex-col items-end justify-center gap-y-[3.4vh] overflow-x-hidden pt-[60px] md:relative md:flex-[569] md:gap-y-[7.6vh]">
          {roleBtImg.map((item) => {
            return (
              <div
                key={item.key}
                className={cn(
                  'relative aspect-[1.65] h-[10.34vh] cursor-pointer bg-[length:100%_100%] bg-no-repeat transition-all hover:translate-x-0 md:aspect-[2.56] md:h-[19.63vh] md:transition-all',
                  role === item.key
                    ? `translate-x-0 ${item.bgSelectedCss}`
                    : "translate-x-[22.30%] bg-[url('/AI_btn_bg_m.png')] md:bg-[url('/AI_btn_bg.png')] md:hover:translate-x-0"
                )}
                onClick={() => {
                  setRole(item.key);
                }}
              >
                <img
                  src={role === item.key ? item.selectedPath : item.path}
                  className={cn(
                    imgLeft(item.key),
                    'absolute bottom-[4px] md:bottom-[6px] '
                  )}
                  alt="direction"
                />
                {item.key === Role.Kimono ? (
                  <div
                    className={cn(
                      role === Role.Kimono
                        ? 'right-[0vh] w-[12.5vh] md:right-[0.30%] md:w-[30.5vh]'
                        : 'right-[3vh] w-[11.5vh] md:right-[15.30%] md:w-[20.5vh]',
                      'absolute bottom-0  flex h-[1.25rem]  justify-center bg-gradient-to-r from-[#e6e4e3]/5 via-[#f49369] to-[rgb(255,110,45)]  text-sm  font-[300] text-[#FFF] hover:translate-x-0  md:bottom-[1.5vh] md:z-10 md:pb-[1.3vh] hover:md:right-[0.30%]  hover:md:w-[30.5vh] lg:h-[1.5rem] lg:text-base '
                    )}
                  >
                    Live 2D
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
