/* eslint-disable @typescript-eslint/no-unsafe-return */
import { toast } from 'react-toastify';

import { Role } from '@/components/pages/home/constant';
import api from '@/services/api';

export async function waitTime(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
}

// type CommonKey = { type?: string } & { isStory?: boolean | string };
interface CommonKey {
  isStory?: boolean | string;
}

export function getQuery(search): CommonKey {
  const obj = {};
  search
    ?.slice(1)
    .split('&')
    .forEach((item) => {
      const [k, v] = item.split('=');
      if (k) {
        obj[k] = v;
      }
    });
  return obj;
}

/**
 * 根据角色获取agentId
 * @param role
 * @returns
 */
export const getAgentId = (role) => {
  switch (role) {
    // aimme
    case Role.Alice:
      return '0d337348503d42a8826442b763ad31f2';
      break;
    // Asaka
    case Role.Kimono:
      return 'a8200f46b8cc4cfb8dd9421d40aef829';
    // julia
    case Role.Maid:
      return 'ae1546584c3448d39654025153a1708d';
    default:
      return 'ae1546584c3448d39654025153a1708d';
  }
};

/**
 * 根据agentId获取sessionId
 * @param role 当前的角色
 * @returns
 */
export const getSessionId = async (role) => {
  const agentId = getAgentId(role);
  try {
    const res = await api.Session.query2CAgentSessionUsingGET({
      agentId: getAgentId(role)
    });
    if (res?.status === 'OK' && !!res?.data) {
      return res?.data;
    } else {
      const createRes = await api.Session.create2CAgentSessionUsingPOST({
        agentId
      });

      if (createRes?.status === 'OK') {
        return createRes?.data || '';
      } else {
        toast.error('Failed to create session id. Please try again.');
      }
    }
  } catch (error) {
    toast.error('Interface request failed');
  }
};

/**
 * 根据role personaId storybookId获取sessionId
 * @param role
 * @param personaId
 * @param storybookId
 * @returns
 */

export const getSessionIdByPersonaId = async (
  role: string,
  personaId: string,
  storybookId: string
) => {
  const agentId = getAgentId(role);
  try {
    const res = await api.Session.query2CStorybookSessionUsingGET({
      agentId,
      personaId,
      storybookId
    });
    if (res?.status === 'OK' && !!res?.data) {
      return res?.data || '';
    } else {
      const createRes = await api.Session.create2CStorybookSessionUsingPOST({
        agentId,
        userPersonaId: personaId,
        storybookId
      });

      if (createRes?.status === 'OK') {
        return createRes?.data || '';
      } else {
        toast.error('Failed to create session id. Please try again.');
        return '';
      }
    }
  } catch (error) {
    toast.error('Interface request failed');
    return '';
  }
};

export const getAvatar = (index) => {
  const avatarList = [
    '/avatars/avatar1.png',
    '/avatars/avatar2.png',
    '/avatars/avatar3.png',
    '/avatars/avatar4.png',
    '/avatars/avatar5.png'
  ];
  return index < avatarList?.length
    ? avatarList[index]
    : avatarList[index % avatarList?.length];
};
