// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List Session Dialogs. GET /api/dialogs/${param0} */
export async function listDialogsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listDialogsUsingGETParams,
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.ResponseListDialog_>(`/api/dialogs/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** List Session Dialog Summaries. GET /api/dialogs/summary/${param0} */
export async function listDialogSummaryUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listDialogSummaryUsingGETParams,
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.ResponseListUserDialogSummary_>(`/api/dialogs/summary/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
