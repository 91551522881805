// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as Agent from './Agent';
import * as Auth from './Auth';
import * as Dialog from './Dialog';
import * as ChatFlow from './ChatFlow';
import * as Instruction from './Instruction';
import * as Log from './Log';
import * as UserPersona from './UserPersona';
import * as Room from './Room';
import * as Session from './Session';
import * as Share from './Share';
import * as Storybook from './Storybook';
import * as User from './User';
export default {
  Agent,
  Auth,
  Dialog,
  ChatFlow,
  Instruction,
  Log,
  UserPersona,
  Room,
  Session,
  Share,
  Storybook,
  User,
};
