// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Create Dialog Share. POST /api/share/dialog */
export async function createDialogShareUsingPOST(
  body: API.CreateDialogShareDTO,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/share/dialog', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Query Dialog Share. GET /api/share/dialog/${param0} */
export async function getDialogShareUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDialogShareUsingGETParams,
  options?: { [key: string]: any },
) {
  const { shareId: param0, ...queryParams } = params;
  return request<API.ItemResponseDialogShareDTO_>(`/api/share/dialog/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Query All Dialog Share IDs. GET /api/share/dialogs */
export async function getDialogsShareListUsingGET(options?: { [key: string]: any }) {
  return request<API.ItemResponseListString_>('/api/share/dialogs', {
    method: 'GET',
    ...(options || {}),
  });
}
