// 导航栏
export enum Nav {
  PAGE_AGENT = 'Agents',
  PAGE_STORY = 'Story',
  PAGE_JOURNEY = 'Journey'
}
interface INavItem {
  title: string;
  path: string;
  key: string;
}

export const NavList: INavItem[] = [
  {
    title: 'Agents',
    path: '/agents',
    key: Nav.PAGE_AGENT
  },
  { title: 'Storybooks', path: '/storybooks', key: Nav.PAGE_STORY },
  { title: 'Journeys', path: '/journeys', key: Nav.PAGE_JOURNEY }
];
