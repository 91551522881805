import cn from 'classnames';
import React from 'react';

import { useAppStore } from '@/store/appStore';

// todo 补全类型
interface IProps {
  personality: any;
}
export function PersonInfo(props: IProps) {
  const { personality } = props;
  const { role } = useAppStore();
  return (
    <div className="flex flex-col font-franklin font-bold">
      <div>
        <div className="text-2xl  uppercase tracking-tight md:pb-[10px] md:text-5xl lg:text-6xl">
          {role === 'maid' ? 'Male' : 'Female '}
        </div>
        <div
          className={cn(
            role === 'maid' ? 'text-[#6EC3FF]' : 'text-[#FF6E2D] ',
            ' flex flex-nowrap text-xl  uppercase leading-5 tracking-tight   md:text-5xl lg:text-6xl'
          )}
        >
          {personality?.name}
        </div>
      </div>
      <div className="mb-[0.5vh] h-[5px] w-[90%] rounded-[5px] border-[0.2rem] border-solid border-[#FFF] md:mb-[1vh] md:h-[5px] md:w-[100%] md:border-[0.2rem]"></div>
      <div>
        <div className="mb-[0.5vh] flex flex-col gap-[0.5vh] md:mb-[2.1rem] md:flex-row md:gap-[2vh]">
          <div className="flex-[3] text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-3xl">
            Age:
          </div>
          <div className="flex-[1] text-xs    md:text-sm  lg:text-base xl:text-xl 2xl:text-xl 3xl:text-3xl">
            {personality?.age}
          </div>
        </div>
        <div className="flex flex-col gap-[0.5vh] md:mb-[2.1rem]  md:gap-[1.4rem]">
          <div className="flex-[3] text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-3xl">
            Personality:
          </div>
          <div className="leading-1 flex-[1] text-xs md:text-sm md:leading-[2.8vh]  lg:text-base xl:text-xl 2xl:text-xl 3xl:text-3xl">
            {personality?.shortDesc}
          </div>
        </div>
      </div>
    </div>
  );
}
