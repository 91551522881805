import cn from 'classnames';
import { Link } from 'gatsby';
import React, { Fragment } from 'react';

import { NavList } from '@/constant';
import { useAppStore } from '@/store/appStore';
import { usePageScrolled } from '@/utils/hooks/usePageScrolled';

import { AccountArea } from './AccountArea';

interface HeaderProps {
  className?: string;
}

// according to https://www.hyperui.dev/components/marketing/navigations
export function Header(props: HeaderProps) {
  const { className } = props;
  const isScrolled = usePageScrolled();
  const { setNav, nav } = useAppStore();

  return (
    <header
      className={cn(
        'page-layout sticky inset-x-0 top-0 z-50 h-[71px] min-h-[71px] bg-[white]/90 font-poppins font-light lg:h-[80px] lg:max-h-[80px] lg:min-h-[80px] 3xl:h-[84px] 3xl:max-h-[84px]',
        className
      )}
    >
      <div
        className={cn(
          'page-container flex h-full border-b border-slate-900/10 backdrop-blur',
          isScrolled ? 'bg-[white]/90' : 'bg-[white]/90'
        )}
      >
        <div className="mx-auto flex h-full w-full justify-around py-2 md:w-11/12">
          <div className="flex flex-[20] items-center">
            <a className="md:hidden">
              <img src="/icon_m.png" className="h-full scale-75" />
            </a>
            <a className="hidden md:block md:w-[30vh]">
              <img
                src="/icon_pc.png"
                alt="icon"
                className="h-full scale-90"
              ></img>
            </a>
            <ul className="flex h-full items-center  space-x-1 text-xs text-[#388367]  md:space-x-8 lg:text-xl ">
              {NavList.map((item, index) => {
                return (
                  <Fragment key={item.key}>
                    <li
                      key={item.title}
                      className={cn(nav === item.key ? ' text-[#B1DB00] ' : '')}
                      onClick={() => setNav(item.key)}
                    >
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                    {index !== NavList.length - 1 && (
                      <span className="h-5 w-[1px] border-[1px] border-solid border-[#CBCBCB] md:h-6 "></span>
                    )}
                  </Fragment>
                );
              })}
            </ul>
          </div>
          <AccountArea />
        </div>
      </div>
    </header>
  );
}
