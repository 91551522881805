import '@/styles/global.css';

import React from 'react';

import { Layout, RootElement } from '@/components';

export const wrapRootElement = ({ element }: { element: JSX.Element }) => {
  return <RootElement>{element}</RootElement>;
};

// Wraps every page in a component
export const wrapPageElement = ({
  element,
  props
}: {
  element: JSX.Element;
  props: Record<string, unknown>;
}) => {
  return <Layout {...props}>{element}</Layout>;
};
