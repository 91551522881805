// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Get Current User's Persona List. GET /api/personas */
export async function listOfCurrentUserUsingGET(options?: { [key: string]: any }) {
  return request<API.ItemResponseListUserPersona_>('/api/personas', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Create User Persona. POST /api/personas */
export async function createUserPersonaUsingPOST(
  body: API.CreateOrUpdateUserPersonaRequest,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseUserPersona_>('/api/personas', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get User Persona. GET /api/personas/${param0} */
export async function getUserPersonaUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserPersonaUsingGETParams,
  options?: { [key: string]: any },
) {
  const { personaId: param0, ...queryParams } = params;
  return request<API.ItemResponseUserPersona_>(`/api/personas/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Update User Persona. PUT /api/personas/${param0} */
export async function updateUserPersonaUsingPUT(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.updateUserPersonaUsingPUTParams,
  body: API.CreateOrUpdateUserPersonaRequest,
  options?: { [key: string]: any },
) {
  const { personaId: param0, ...queryParams } = params;
  return request<API.ItemResponseUserPersona_>(`/api/personas/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** Delete User Persona. DELETE /api/personas/${param0} */
export async function deleteUserPersonaUsingDELETE(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.deleteUserPersonaUsingDELETEParams,
  options?: { [key: string]: any },
) {
  const { personaId: param0, ...queryParams } = params;
  return request<API.ItemResponseUserPersona_>(`/api/personas/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Search User Persona. GET /api/personas/search */
export async function searchListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.searchListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResponseUserPersona_>('/api/personas/search', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
