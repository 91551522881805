// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Query Chat Flow By Flow Name. GET /api/flows */
export async function queryChatFlowUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryChatFlowUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResponseChatFlow_>('/api/flows', {
    method: 'GET',
    params: {
      // pageNo has a default value: 1
      pageNo: '1',
      // size has a default value: 10
      size: '10',
      ...params,
    },
    ...(options || {}),
  });
}

/** Create Chat Flow. POST /api/flows */
export async function createChatFlowUsingPOST(
  body: API.SaveChatFlowRequest,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseChatFlow_>('/api/flows', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get Chat Flow. GET /api/flows/${param0} */
export async function getChatFlowUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getChatFlowUsingGETParams,
  options?: { [key: string]: any },
) {
  const { flowId: param0, ...queryParams } = params;
  return request<API.ItemResponseChatFlow_>(`/api/flows/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Update Chat Flow. PUT /api/flows/${param0} */
export async function updateChatFlowUsingPUT(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.updateChatFlowUsingPUTParams,
  body: API.SaveChatFlowRequest,
  options?: { [key: string]: any },
) {
  const { flowId: param0, ...queryParams } = params;
  return request<API.ItemResponseChatFlow_>(`/api/flows/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** Delete Chat Flow. DELETE /api/flows/${param0} */
export async function deleteChatFlowUsingDELETE(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.deleteChatFlowUsingDELETEParams,
  options?: { [key: string]: any },
) {
  const { flowId: param0, ...queryParams } = params;
  return request<API.ResponseString_>(`/api/flows/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}
