// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List All User profile. GET /api/users */
export async function listUserProfilesUsingGET(options?: { [key: string]: any }) {
  return request<API.ItemResponseListUserProfile_>('/api/users', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Query User profile By Agent Id. GET /api/users/${param0} */
export async function queryAgentUsersUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryAgentUsersUsingGETParams,
  options?: { [key: string]: any },
) {
  const { agentId: param0, ...queryParams } = params;
  return request<API.ItemResponseListUserProfile_>(`/api/users/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Query Relationship By User Id & Agent Id. GET /api/users/relationship */
export async function queryRelationshipUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryRelationshipUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.ItemResponseString_>('/api/users/relationship', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
