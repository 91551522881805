import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
// import Role from '@components/pages/chat/'

interface AppState {
  // layout
  nav: string;
  setNav: (nav: string) => void;
  count: number;
  increase: (by: number) => void;
  showLoginModal: boolean;
  setShowLoginModal: (show: boolean) => void;
  role: string;
  setRole: (name: string) => void;
  isLogin: boolean;
  switchLog: (name: boolean) => void;
  startWord: string;
  lastWord: string;
  changeWord: (_start: string, _last: string) => void;
  story: string;
  setStory: (name: string) => void;
  shareId: string;
  setShareId: (id: string) => void;
  isStory: boolean;
  setIsStory: (show: boolean) => void;

  isFirst: boolean;
  setIsFirst: (first: boolean) => void;
}

// 默认集成了redux devtool方便观察数据流，immer做数据不可变，persist做数据持久化
export const useAppStore = create<AppState>()(
  devtools(
    immer(
      persist(
        (set, get) => ({
          nav: '',
          setNav: (nav: string) =>
            set(
              (state) => {
                state.nav = nav;
              },
              false,
              'setNav'
            ),
          // 通过set来更新state，通过get() 可以获取到当前最新值
          count: 0,
          increase: (by) =>
            set(
              (state) => {
                state.count += by;
              },
              // 使用immer这里只能是false
              false,
              // redux devtool中显示的 action名
              'count/increase'
            ),
          showLoginModal: false,
          setShowLoginModal: (show: boolean) =>
            set(
              (state) => {
                state.showLoginModal = show;
              },
              false,
              'setShowLoginModal'
            ),
          // todo: 修改初始值 使用枚举得代替，不使用具体得字符串值 防止错误
          role: 'kimono',
          setRole: (name: string) =>
            set(
              (state) => {
                state.role = name;
              },
              false,
              'setRole'
            ),
          isLogin: true,
          switchLog: (loginVar: boolean) =>
            set(
              (state) => {
                state.isLogin = loginVar;
              },
              false,
              'switchLog'
            ),
          startWord: '',
          lastWord: '',
          changeWord: (_start, _last) =>
            set(
              (state) => {
                state.startWord = _start;
                state.lastWord = _last;
              },
              false,
              'changeWord'
            ),
          story: '10db719268ab4a11a378b44c64ce4b4c',
          setStory: (id: string) =>
            set(
              (state) => {
                state.story = id;
              },
              false,
              'setStory'
            ),
          shareId: '10db719268ab4a11a378b44c64ce4b4c',
          setShareId: (id: string) =>
            set(
              (state) => {
                state.shareId = id;
              },
              false,
              'setShareId'
            ),
          isStory: false,
          setIsStory: (show: boolean) =>
            set(
              (state) => {
                state.isStory = show;
              },
              false,
              'setIsStory'
            ),

          isFirst: true,
          setIsFirst: (first: boolean) =>
            set(
              (state) => {
                state.isFirst = first;
              },
              false,
              'setIsFirst'
            )
        }),
        {
          // state存储到local storage中的key名
          name: 'app-storage'
        }
      )
    )
  )
);
