// @ts-ignore
/* eslint-disable */
import request from '../request';

/** Query Storybook List GET /api/storybooks */
export async function listUsingGET(options?: { [key: string]: any }) {
  return request<API.ItemResponseListStorybookDTO_>('/api/storybooks', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Add Storybook POST /api/storybooks */
export async function addUsingPOST(body: API.UpsertStorybookDTO, options?: { [key: string]: any }) {
  return request<API.ItemResponseStorybookDTO_>('/api/storybooks', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get Storybook GET /api/storybooks/${param0} */
export async function getUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUsingGETParams,
  options?: { [key: string]: any },
) {
  const { storybookId: param0, ...queryParams } = params;
  return request<API.ItemResponseStorybookDTO_>(`/api/storybooks/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Update Storybook POST /api/storybooks/${param0} */
export async function updateUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.updateUsingPOSTParams,
  body: API.UpsertStorybookDTO,
  options?: { [key: string]: any },
) {
  const { storybookId: param0, ...queryParams } = params;
  return request<API.ItemResponseStorybookDTO_>(`/api/storybooks/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
