// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List All Instruction. GET /api/instructions */
export async function listInstructionsUsingGET(options?: { [key: string]: any }) {
  return request<API.ResponseListInstruction_>('/api/instructions', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Edit Instruction. PUT /api/instructions */
export async function editInstructionUsingPUT(
  body: API.Instruction,
  options?: { [key: string]: any },
) {
  return request<API.ResponseBoolean_>('/api/instructions', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Add Instruction. POST /api/instructions */
export async function addInstructionUsingPOST(
  body: API.Instruction,
  options?: { [key: string]: any },
) {
  return request<API.ResponseBoolean_>('/api/instructions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get Instruction. GET /api/instructions/${param0} */
export async function getInstructionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getInstructionUsingGETParams,
  options?: { [key: string]: any },
) {
  const { instructionId: param0, ...queryParams } = params;
  return request<API.ResponseString_>(`/api/instructions/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
