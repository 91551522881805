// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List All Agent Profile. GET /api/agents */
export async function listAgentProfilesUsingGET(options?: { [key: string]: any }) {
  return request<API.ResponseListAgentProfile_>('/api/agents', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Edit Agent Profile. PUT /api/agents */
export async function editAgentProfileUsingPUT(
  body: API.AgentProfile,
  options?: { [key: string]: any },
) {
  return request<API.ResponseBoolean_>('/api/agents', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Add Agent Profile. POST /api/agents */
export async function addAgentProfileUsingPOST(
  body: API.AgentProfile,
  options?: { [key: string]: any },
) {
  return request<API.ResponseString_>('/api/agents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get Agent Profile. GET /api/agents/${param0} */
export async function getAgentProfileUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAgentProfileUsingGETParams,
  options?: { [key: string]: any },
) {
  const { agentId: param0, ...queryParams } = params;
  return request<API.ResponseAgentProfile_>(`/api/agents/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
