import {
  getDefaultAvatar,
  login,
  logout,
  register,
  useAuthStore
} from '@white-matrix/matrix-universe-auth-sdk';
import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { Button } from '../ui/button';

interface IAccountAreaProps {
  className?: string;
}
export function AccountArea(props: IAccountAreaProps) {
  const { className } = props;
  const { userInfo } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [isLogout, setIslogout] = useState(false);
  const ref = useRef(null);
  const logoutref = useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });
  useClickAway(logoutref, () => {
    setIslogout(false);
  });

  if (userInfo?.userId) {
    return (
      <div
        className={cn(className, ' flex h-full items-center justify-center')}
      >
        <img
          id="avatar"
          src={userInfo.avatar || getDefaultAvatar(userInfo.email)}
          className=" my-1 mr-2 h-[38px] w-[38px] cursor-pointer rounded-[50%] hover:shadow-2xl"
          onClick={() => setOpen(true)}
        ></img>

        {open && (
          <div
            ref={ref}
            className=" absolute right-[-1rem] top-10 scale-75 divide-y divide-[#8F8F8F]/40 rounded-lg  bg-[white] md:top-16 md:right-5 md:scale-100"
          >
            <div className="flex gap-x-2 rounded-t-lg bg-[#C3CBA0]/20 px-2 py-4">
              <img
                id="avatar"
                src={userInfo.avatar || getDefaultAvatar(userInfo.email)}
                className=" my-1 h-[38px] w-[38px] cursor-pointer rounded-[50%] hover:shadow-2xl"
              ></img>
              <div className="md:ml-3">
                <div className="font-normal">{userInfo.username} </div>
                <div className="text-xs">{userInfo?.email}</div>
              </div>
              {/* <div className="divide-y divide-[#8F8F8F]/40"></div> */}
              {/* <Separator /> */}
            </div>
            <div className="space-y-1  divide-y divide-[#8F8F8F]/40 py-4 text-[#388367] md:space-y-4">
              <div
                className="w-[100%] cursor-pointer text-center hover:text-[#B1DB00] "
                onClick={() => {
                  void navigate('/profile');
                }}
              >
                Profile
              </div>
              <div
                className="w-[100%] cursor-pointer space-y-1 pt-4 text-center hover:text-[#B1DB00] md:space-y-4"
                onClick={() => setIslogout(true)}
              >
                Log out
              </div>
            </div>
          </div>
        )}
        {isLogout && (
          <div
            className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  absolute inset-0 h-[100vh] bg-black/80"
            style={{ zIndex: 9999 }}
          >
            <div
              ref={logoutref}
              className="absolute top-1/2 left-1/2  flex  h-[12%] w-[90%] -translate-x-1/2 -translate-y-1/2 flex-col justify-between rounded-sm bg-[white] p-4  text-black md:w-[20%]"
            >
              <div className=" text-lg font-semibold md:text-xl">
                Are you sure you want to log out?
              </div>
              <div className="flex justify-end gap-3">
                <Button
                  variant="outline"
                  onClick={() => {
                    setIslogout(false);
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setIslogout(false);
                    logout();
                  }}
                >
                  confirm
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="mr-1 flex h-full flex-[3] flex-row items-center justify-center space-x-1 text-[#388367] md:space-x-3 ">
        <button
          className="me-2 h-[3.3vh] w-[6.84vh] rounded bg-[#FFF] text-xs   text-[#388367] ring-1 ring-[#388367] hover:bg-[#b1db00] hover:text-[white]  hover:ring-0 focus:outline-none focus:ring-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-[#388367] md:h-[30px] md:w-[100px]"
          onClick={login}
        >
          Login
        </button>
        <button
          onClick={register}
          className="me-2 h-[3.3vh] w-[6.84vh] rounded border border-gray-200  bg-white text-xs  text-[#388367] ring-1 ring-[#388367] hover:bg-[#b1db00] hover:text-[white] hover:ring-0 focus:z-10 focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 md:h-[30px] md:w-[100px]"
        >
          Register
        </button>
      </div>
    );
  }
}
