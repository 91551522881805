// @ts-ignore
/* eslint-disable */
import request from '../request';

/** List All Room Decoration. GET /api/rooms */
export async function listRoomDecorationsUsingGET(options?: { [key: string]: any }) {
  return request<API.ResponseListRoomDecoration_>('/api/rooms', {
    method: 'GET',
    ...(options || {}),
  });
}

/** Edit Room Decoration. PUT /api/rooms */
export async function editRoomDecorationUsingPUT(
  body: API.RoomDecoration,
  options?: { [key: string]: any },
) {
  return request<API.ResponseBoolean_>('/api/rooms', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Add Room Decoration. POST /api/rooms */
export async function addRoomDecorationUsingPOST(
  body: API.RoomDecoration,
  options?: { [key: string]: any },
) {
  return request<API.ResponseBoolean_>('/api/rooms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Get Room Decoration By Room Id. GET /api/rooms/${param0} */
export async function getRoomDecorationUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getRoomDecorationUsingGETParams,
  options?: { [key: string]: any },
) {
  const { roomId: param0, ...queryParams } = params;
  return request<API.ResponseRoomDecoration_>(`/api/rooms/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
